<template>
  <div class="manageLinkedDeviceContainer">
    <v-snackbar v-model="requestTagConfirmation" :timeout="3000" color="success">
      <strong>{{ $t("linkDevice.requestTag.response.success") }}</strong>
    </v-snackbar>
    
    <v-snackbar v-model="requestTagError" :timeout="3000" color="red">
      <strong>{{ $t("linkDevice.requestTag.response.error") }}</strong>
    </v-snackbar>

    <h1>{{ $t("linkDevice.title_manage_device") }}</h1>

    <!-- Basic Info -->
    <h2>{{ $t("linkDevice.info") }}</h2>
    <v-text-field :value="form.ids.join(', ')" label="id" disabled readonly></v-text-field>
    <v-text-field v-model="form.name" :label="$t('linkDevice.name_device')" :disabled="form.ids.length > 1" :readonly="form.ids.length > 1" @change="handleDeviceChange"></v-text-field>
    <v-autocomplete
      clearable
      :label="$t('linkDevice.city')"
      :items="citiesSelect"
      v-model="form.city"
      :search-input.sync="searchInputCity"
      @change="handleCityChange"
    ></v-autocomplete>

    <!-- Customize -->
    <h2 v-if="user.permissions.editStream != 0">{{ $t("linkDevice.customize") }}</h2>
    <v-expansion-panels v-if="user.permissions.editStream != 0">

      <v-expansion-panel>
        <v-expansion-panel-header>{{$t('linkDevice.announcements.label')}}</v-expansion-panel-header>
        <v-expansion-panel-content>
          <Calendar :streamIds="form.ids" @change="handleCalendarChange"></Calendar> 
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel>
        <v-expansion-panel-header>{{$t('linkDevice.moment.label')}}</v-expansion-panel-header>
        <v-expansion-panel-content>
          <p v-if="form.ids.length > 1">{{ $t("linkDevice.changes.moments") }}</p>

          <!-- Moments -->
          <table class="momentTable">
            <thead>
              <tr>
                <th class="hidden"></th>
                <th class="text-left">{{$t('linkDevice.moment.table.name')}}</th>
                <th class="text-left">{{$t('linkDevice.moment.table.from')}}</th>
                <th class="text-left">{{$t('linkDevice.moment.table.to')}}</th>
                <th class="text-right">{{$t('linkDevice.moment.table.active')}}</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(row, index) in form.moments"
                :key="index"
              >
                <td class="hidden">{{ row.id }}</td>
                <td>{{ row.text }}</td>
                <td>
                  <Time v-model="form.moments[index].start_time" @change="handleMomentTimeChange(index)"/>
                </td>
                <td>
                  <Time v-model="form.moments[index].end_time" @change="handleMomentTimeChange(index)"/>
                </td>
                <td><v-switch v-model="form.moments[index].enabled" :disabled="user.permissions.editStream == 0" @change="handleDeviceChange"></v-switch></td>
              </tr>
            </tbody>
          </table>

        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel>
        <v-expansion-panel-header>{{$t('linkDevice.dayprogram.label')}}</v-expansion-panel-header>
        <v-expansion-panel-content eager>
          <p v-if="form.ids.length > 1">{{ $t("linkDevice.changes.dayprogram") }}</p>

          <div class="dayprogramContainer">
            <!-- :streamId loads personal dayprogram, in case of multiple form.ids, the default program gets loaded -->
            <Dayprogram :showSaveButton="false" :streamId="(form.ids.length == 1) ? parseInt(form.ids[0]) : undefined" @change="handleDayprogramChange" ref="dayprogram"></Dayprogram>
          </div>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel>
        <v-expansion-panel-header>{{$t('linkDevice.photoAlbum')}}</v-expansion-panel-header>
        <v-expansion-panel-content>
          <!-- TODO -->
          <p v-if="form.ids.length > 1">{{ $t("linkDevice.changes.photoAlbum") }}</p> 

          <div class="photoAlbumContainer">
            <PhotoAlbum :streamIds="form.ids" ref="photoAlbum" @change="handlePhotoAlbumChange" @error="handlePhotoAlbumError"></PhotoAlbum>
          </div>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel>
        <v-expansion-panel-header>{{ $t("linkDevice.tag.label") }}</v-expansion-panel-header>
        <v-expansion-panel-content>
          <p v-if="form.ids.length > 1">{{ $t("linkDevice.changes.tags") }}</p>

          <div class="d-flex pb-3" style="gap:10px">

            <v-autocomplete
              closable-chips
              deletable-chips
              chips
              :items="tagSelectOptions"
              multiple
              v-model="form.tags"
              item-text="name"
              item-value="value"
              :disabled="user.permissions.editStream == 0"
              prepend-inner-icon="mdi-magnify"
              @change="handleTagChange"
              :search-input.sync="searchInput"
              class="mt-0 pt-0"
              hide-details
            >

              <template v-slot:selection></template>

              <template v-slot:item="data">
                  <template>
                      <v-list-item-action>
                        <v-simple-checkbox :value="form.tags.includes(data.item.value)" @click.stop="handleCheckboxClick(data)"/>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title>
                            {{ data.item.name }}
                        </v-list-item-title>
                        <v-list-item-subtitle>
                            {{ data.item.group.name }}
                        </v-list-item-subtitle>
                      </v-list-item-content>
                  </template>
              </template>

              <template v-slot:no-data>
                <div role="listbox" tabindex="-1" class="v-list v-select-list v-sheet theme--light theme--light">
                  <div tabindex="-1" class="v-list-item theme--light">
                    <div class="v-list-item__content">
                      <div class="v-list-item__title">
                        Geen resultaten gevonden. <a href="#" @click.prevent="openRequestTag">Klik hier</a> om de interesse <strong>{{searchInput}}</strong> aan te vragen.
                      </div>
                    </div>
                  </div>
                </div>
              </template>

            </v-autocomplete>

            <v-btn @click="showFilterBar = !showFilterBar">
              <v-icon>mdi-filter</v-icon>
              Filter
            </v-btn>

          </div>

          <div v-if="showFilterBar" class="d-flex flex-column flex-md-row justify-space-around pa-3 mb-5 grey lighten-4">
            <span>Filter:</span>
            <v-checkbox
                v-for="category in categoryMap"
                :key="category.slug"
                v-model="selectedCategories"
                :label="category.name"
                :value="category.slug"
                hide-details
                class="mt-0 pt-0"
            ></v-checkbox>
          </div>

          <div>
            <v-row>
              <v-col
                v-for="category of categoryMap"
                :key="category.slug"
                class="tagCategory"
                cols="12"
                sm="4"
                md="2"
              >

                <strong>{{ category.name }}</strong>
                <div v-for="tag of filteredTags(category)" :key="tag.id" class="d-flex justify-space-between align-center py-1">
                    <span>
                      {{ tag.tag }}
                    </span>
                    <v-btn
                      icon
                      x-small
                      color="red lighten-3"
                      @click="deleteTag(tag.id)"
                      :disabled="user.permissions.editStream == 0"
                    >
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                </div>

              </v-col>
            </v-row>
          </div>

        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel>
        <v-expansion-panel-header>{{$t('linkDevice.favorite')}}</v-expansion-panel-header>
        <v-expansion-panel-content>
          <p v-if="form.ids.length > 1">{{ $t("linkDevice.changes.favorites") }}</p>

          <!-- Favorites -->
          <v-autocomplete
            clearable
            closable-chips
            deletable-chips
            chips
            :items="videoSelect"
            multiple
            v-model="form.videos"
            item-text="title"
            item-value="id"
            :search-input.sync="searchInput"
            @change="handleFavoriteChange"
            :disabled="user.permissions.editStream == 0"
          ></v-autocomplete>

        </v-expansion-panel-content>
      </v-expansion-panel>

    </v-expansion-panels>

    <v-dialog v-model="modal.tagRequest.display" width="500">
      <v-card>
        <v-card-title>
            {{ $t(`linkDevice.requestTag.modal.title`) }}
        </v-card-title>
        
        <div class="modalBody">

          <!-- Tag -->
          <v-text-field
            v-model="modal.tagRequest.form.tag"
            :label="$t(`linkDevice.requestTag.modal.fields.tag`)"
            required
          ></v-text-field>

          <!-- Name -->
          <v-text-field
            v-model="modal.tagRequest.form.name"
            :label="$t(`linkDevice.requestTag.modal.fields.name`)"
          ></v-text-field>

          <!-- Email -->
          <v-text-field
            v-model="modal.tagRequest.form.email"
            :label="$t(`linkDevice.requestTag.modal.fields.email`)"
          ></v-text-field>

          <!-- Accounts -->
          <v-switch
            :input-value="allAccountsSelected"
            :label="$t(`linkDevice.requestTag.modal.fields.toggleAccounts`)"
            @change="toggleAllAccounts"
          ></v-switch>
          
          <v-autocomplete
            v-model="modal.tagRequest.form.accounts"
            :items="this.accounts"
            :label="$t(`linkDevice.requestTag.modal.fields.accounts`)"
            :item-text="account => `${account.location} ${account.name}`.trim()"
            item-value="stream_id"
            multiple
          ></v-autocomplete>

          <!-- Remark -->
          <v-textarea
            v-model="modal.tagRequest.form.remark"
            :label="$t(`linkDevice.requestTag.modal.fields.remark`)"
          ></v-textarea>

        </div>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="modal.tagRequest.display = false">
            {{ $t("linkDevice.requestTag.modal.buttons.cancel") }}
          </v-btn>
          <v-btn @click="requestTag" color="primary">
            {{ $t(`linkDevice.requestTag.modal.buttons.submit`) }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Buttons -->
    <div class="text-right buttonContainer">
      <v-btn href="/gekoppelde-apparaten/">{{ $t("linkDevice.cancel") }}</v-btn>
      <v-btn color="primary" @click="submit()" :disabled="saveButtonDisabled">
        <span class="pr-2" v-show="saveButtonLoading"><i class="fa fa-circle-notch fa-spin"></i></span>{{ $t("linkDevice.submit") }}
      </v-btn>
    </div>

    <!-- Error -->
    <v-snackbar v-model="form.error.display" :timeout="form.error.timeout" color="red">
      <strong>{{ form.error.message }}</strong>
    </v-snackbar>

  </div>
</template>

<script>
import Vue from "vue";
import axios from "axios";
import { mapGetters } from "vuex";
import Dayprogram from '@/components/Dayprogram.vue';
import Calendar from '@/components/Calendar.vue';
import PhotoAlbum from '@/components/PhotoAlbum.vue';
import Time from '@/components/Time.vue';
import 'vue2-datepicker/index.css';
import 'vue2-datepicker/locale/nl';
import { ILLI_API_SERVER_URL, DAYPROGRAM_IMAGES_URL } from "../config";
import { EventBus } from '@/utils/eventBus';
import { displayName } from '@/utils/tagSystem';

export default Vue.extend({
  components: {
    Dayprogram,
    Calendar,
    PhotoAlbum,
    Time
  },
  data: () => ({
    tagSelectOptions: [],

    categoryMap: [
      {include: {categories: [6]}, name: 'Steden & plaatsen', slug: 'city'},
      {include: {categories: [3,4]}, name: 'Muziek', slug: 'music'},
      {include: {categories: [14,15]}, name: 'Natuur & dieren', slug: 'nature'},
      {include: {categories: [5]}, name: 'Landen', slug: 'country'},
      {include: {categories: [16]}, name: 'Sport', slug: 'sport'},
      {include: {tags: [1477, 1419, 90]}, exclude: {categories: [1, 3, 4, 5, 6, 14, 15, 16]}, name: 'Overig', slug: 'other'}
    ],
    selectedCategories: [],
    showFilterBar: false,

    requestTagConfirmation: false,
    requestTagError: false,
    searchInput: '', // used to empty input field after selection
    searchInputCity: '', // used to empty input field after selection
    searchInputTag: '', // used to empty input field after selection
    form: {
      ids: [],
      name: '', 
      city: '',
      tags: [],
      videos: [],
      moments: [],
      announcements: {
        post: [],
        patch: [],
        delete: []
      },
      program: [],
      programValidation: {
        valid: true,
        message: null
      },
      programChanged: false,
      error: {
        display: false,
        message: '',
        timeout: 3000
      },
    },
    modal: {
      delete: false,
      tagRequest: {
        display: false,
        form: {
          tag: '',
          name: '',
          email: '',
          accounts: [],
          remark: '',
        }
      }
    },
    allTags: [],
    allVideos: [],
    tagCategorySelect: [],
    tagCategoryNames: [],
    removeAnnouncements: [],
    videoSelect: [],
    citiesSelect: [],
    defaultMoments: [
      {id: 0, text: 'Ontbijt', start_time: '08:00', end_time: '09:00', enabled: false},
      {id: 1, text: 'Koffie', start_time: '08:00', end_time: '09:00', enabled: false},
      {id: 2, text: 'Lunch', start_time: '08:00', end_time: '09:00', enabled: false},
      {id: 3, text: 'Koffie', start_time: '08:00', end_time: '09:00', enabled: false},
      {id: 4, text: 'Diner', start_time: '08:00', end_time: '09:00', enabled: false},
      {id: 5, text: 'Koffie', start_time: '08:00', end_time: '09:00', enabled: false},
    ],
    saveButtonDisabled: true,
    saveButtonLoading: false,

    accounts: [],
  }),
  computed: {
    ...mapGetters({
      authenticated: "auth/authenticated",
      user: "auth/user",
      system: "system/system",
    }),
    filteredTags() {
      return (category) => {
        const includeCategories = (category?.include?.categories) 
          ? category.include.categories 
          : this.tagCategorySelect.map(s => s.id).filter(s => !category.exclude.categories.includes(s));

        return this.form.tags
          .filter(st => {
            const tag = this.allTags.find(at => at.id == st);
            if (!tag) return false;
            const excludeTag = (category?.exclude?.tags) ? category.exclude.tags.includes(tag.id) : false;
            const includeTagByCategory = includeCategories.includes(tag.categoryId);
            const includeTagById = (category?.include?.tags) ? category.include.tags.includes(tag.id) : false;
            return ((includeTagByCategory || includeTagById) && !excludeTag);
          })
          .map(tag => this.allTags.find(at => at.id == tag))
          .sort((a,b) => (`${a.tag}`.localeCompare(b.tag)));
      }
    },
    allAccountsSelected() {
      return this.modal.tagRequest.form.accounts.length == this.accounts.length;
    }
  },
  watch: {
    selectedCategories: function(){
      this.createTagSelectOptions();
    }
  },
  created() {
    this.form.ids = `${this.$route.params.id}`.split('-');

    if (this.form.ids.length == 1) {
      const id = this.form.ids[0];
      console.log('found id', id);
      this.loadDevice(id).then(() => {
        this.loadCities();
        this.loadTags();
        this.loadVideos();
      })
    } else if (this.form.ids.length > 1){
      console.log('found ids', this.form.ids);

      this.loadCities();
      this.loadTags();
      this.loadVideos();

      this.form.moments = JSON.parse(JSON.stringify(this.defaultMoments));
    }
  },
  methods: {

    async loadCities() {
        const response = await fetch(`${DAYPROGRAM_IMAGES_URL}Omgeving/`, {method: 'GET'});
        const responseJson = await response.json();	
        this.citiesSelect = Object.values(responseJson);
    },

    getDevice(id){
      console.log('get device', id);
      return new Promise((resolve, reject) => {
        
        axios
          .get(`${ILLI_API_SERVER_URL}/stream/${id}`)
          .then((results) => {

            resolve({
              name: results.data.name,
              city: results.data.city,
              tags: results.data.tags.map(t => t.id),
              videos: results.data.videos.map(f => f.cloudflareId),
              moments: (results.data.stream_moments.length)
                ? results.data.stream_moments
                : JSON.parse(JSON.stringify(this.defaultMoments))
            })

          }).catch((err) => {
            console.error(err);
            reject();
          })

      });
    },

    loadDevice(id) {
      return new Promise((resolve, reject) => {
        console.log('load device', id);
        this.getDevice(id).then((device) => {
          this.form.name = device.name;
          this.form.city = device.city;
          this.form.tags = device.tags;
          this.form.videos = device.videos;
          this.form.moments = device.moments;

          resolve();
        }).catch(() => {
          reject();
        })
      });
    },

    
    loadAccounts() {
      return new Promise((resolve, reject) => {
        if (this.accounts.length < 1){
  
          axios
            .get(`${ILLI_API_SERVER_URL}/stream/accounts`)
            .then((result) => {
    
              const accounts = result.data.accounts.filter((account) => {
                const isParent = result.data.accounts.some((a) => a.parent_stream_id == account.stream_id);
                return !isParent;
              })
    
              this.accounts = accounts;
              resolve(accounts);
    
            })
            .catch((err) => {
              console.error({err});
              reject();
            })
        } else {
          resolve(this.accounts)
        }
      });
    },

    handleCheckboxClick(data) {
      const index = this.form.tags.indexOf(data.item.value);
      if (index === -1) {
        this.form.tags.push(data.item.value);
      } else {
        this.form.tags.splice(index, 1);
      }
      this.handleDeviceChange();
    },

    createTagSelectOptions(){

      this.tagSelectOptions = [];

      const categoryMapFiltered = this.categoryMap.filter(c => this.selectedCategories.includes(c.slug));

      for (const [index, category] of categoryMapFiltered.entries()) {
        const group = {name: category.name, slug: category.slug};

        const includeCategories = (category?.include?.categories) 
          ? category.include.categories 
          : this.tagCategorySelect.map(s => s.id).filter(s => !category.exclude.categories.includes(s));

        // this.tagSelectOptions.push({header: category.name, group: group});
        this.tagSelectOptions.push(
          ...this.allTags
            .filter((t) => {
              const excludeTag = (category?.exclude?.tags) ? category.exclude.tags.includes(t.id) : false;
              const includeTagByCategory = includeCategories.includes(t.categoryId);
              const includeTagById = (category?.include?.tags) ? category.include.tags.includes(t.id) : false;
              return ((includeTagByCategory || includeTagById) && !excludeTag);
            })
            .sort((a,b) => (`${a.tag}`.localeCompare(b.tag)))
            .map(t => ({value: t.id, name: t.tag, group: group}))
            // `${t.tag} (${this.tagCategoryNames[t.categoryId]})`
        )
        if (index != categoryMapFiltered.length - 1) this.tagSelectOptions.push({divider: true, group: group});
      }

      console.warn('tagSelectOptions', JSON.parse(JSON.stringify(this.tagSelectOptions)));

    },

    deleteTag(id){
      this.handleDeviceChange();
      this.form.tags = this.form.tags.filter(e => e != id)
    },

    loadTags() {
      axios
        .get(`${ILLI_API_SERVER_URL}/categoryTags/`)
        .then((results) => {

          const categoryTags = JSON.parse(JSON.stringify(results.data.categoryTags));
          this.allTags = categoryTags.map(c => c.tags).flat();
          this.tagCategorySelect = categoryTags.map(c => c = {id: c.id, name: c.category});
          this.tagCategoryNames = categoryTags.reduce(function(a,b){
            a[b.id] = b.category
            return a;
          },{})

          this.createTagSelectOptions();
          this.selectedCategories = this.categoryMap.map(c => c.slug);
          
        });
    },

    loadVideos(){
      axios
        .get(`${ILLI_API_SERVER_URL}/videos`)
        .then((results) => {
          console.log('videos');
          console.log(results.data.videos);
          this.allVideos = results.data.videos;

          this.videoSelect = this.allVideos.map(v => v = {id: v.cloudflareId, title: displayName(v.video)}).sort((a,b) => {
            return (`${a.title}`).localeCompare(b.title);
          })
        })
    },

    postAnnouncement(announcement){
      console.log('POST', JSON.parse(JSON.stringify(announcement)));
      return axios.post(`${ILLI_API_SERVER_URL}/announcements`, announcement);
    },

    patchAnnouncement(announcement){
      console.log('PATCH', JSON.parse(JSON.stringify(announcement)));
      return axios.patch(`${ILLI_API_SERVER_URL}/announcements/${announcement.id}`, announcement);
    },

    deleteAnnouncement(announcementId, streamIds){
      console.log('DELETE', announcementId, streamIds);
      streamIds = streamIds.join('-');
      return axios.delete(`${ILLI_API_SERVER_URL}/announcement/${announcementId}/ifOnlyBelongsTo/${streamIds}`);
    },

    patchStream(id){
      const multipleIds = (this.form.ids.length > 1);
      return new Promise((resolve, reject) => {
        
        this.getDevice(id).then((device) => {

          // if editing one device; use data. if editing multiple; see if changes occured / add old values

          const nameData = (!multipleIds) ? this.form.name : undefined;
          const cityData = (!multipleIds || this.form.city != '') ? this.form.city : undefined;

          const tagData = (!multipleIds)
            ? this.form.tags
            : [...new Set(this.form.tags.concat(device.tags))]

          const favoriteData = (!multipleIds)
            ? this.form.videos
            : [...new Set(this.form.videos.concat(device.videos))]

          const momentData = (!multipleIds || !this.arrOfObjSame(this.form.moments, this.defaultMoments)) 
            ? this.form.moments
            : undefined;

          const programData = (!multipleIds || this.programChanged)
            ? this.form.program
            : undefined;

          const apiPatchData = {
            name: nameData,
            city: cityData,
            tags: tagData,
            favorites: favoriteData,
            moments: momentData,
            program: programData
          }

          axios
            .patch(`${ILLI_API_SERVER_URL}/stream/${id}`, apiPatchData)
            .then((result) => {
              console.warn({result});
              if (result.status >= 200 && result.status < 300){ // success
    
                const tagsWithInfo = tagData.map(t => this.allTags.filter(a => a.id == t)).flat();

                axios
                  .patch(`${localStorage.getItem('stream-server')}/streamLocation/${id}`, { // stream server
                    name: this.form.name,
                    tags: tagsWithInfo,
                    favorites: favoriteData
                  })
                  .then((result) => {
                    if (result.status >= 200 && result.status < 300){ // success
                      resolve()
                    } else {
                      reject(result)
                    }
                  })
                  .catch((error) => {
                    console.error({error});
                    reject(error);
                  })
    
              } else {
                reject(result);
              }
            })
            .catch((error) => {
              reject(error);
            })

        });

      });
    },

    handleMomentTimeChange(index){
      this.form.moments[index].enabled = true;
      this.handleDeviceChange();
    },

    handlePhotoAlbumError(data){
      this.showError(data.message, data.timeout);
    },

    handleCityChange(){
      this.searchInputCity = '';

      this.handleDeviceChange();
    },

    handleDayprogramChange(data){
      this.programChanged = true;

      this.form.program = data.program;
      this.form.programValidation = data.validation;

      this.handleDeviceChange();
    },

    handleCalendarChange(data){
      this.form.announcements = data.changes;
      
      this.handleDeviceChange();
    },

    handlePhotoAlbumChange(data){
      this.$refs.dayprogram.updatePhotoAlbums(data);

      this.handleDeviceChange();
    },

    handleTagChange(){
      this.searchInput = '';

      this.handleDeviceChange();
    },

    handleFavoriteChange(){
      this.searchInput = '';

      this.handleDeviceChange();
    },

    handleDeviceChange(){
      this.saveButtonDisabled = false;
    },

    submit(){

      console.warn('SUBMIT');

      this.saveButtonDisabled = true;
      this.saveButtonLoading = true;
      this.form.error.display = false;
  
      if (this.form.programValidation.valid){
        if (this.momentsValid()){

          const savePhotoAlbums = (this.$refs.photoAlbum)
            ? this.$refs.photoAlbum.save()
            : Promise.resolve([]);

          savePhotoAlbums.then((albums) => {

            // Process new album ids in program
            const newAlbums = albums.filter(a => a.new).map(a => ({id: a.id, tempId: a.tempId}));
            
            this.form.program.map((item) => {
              // Replace tempIds with autogenerated ids
              if (item.album_id){
                const newAlbum = newAlbums.find(a => a.tempId == item.album_id);
                if (newAlbum) {
                  item.album_id = newAlbum.id;
                }
              }
              return item
            })

            const postAnnouncements = this.form.announcements.post.map(a => this.postAnnouncement(a));
            const patchAnnouncements = this.form.announcements.patch.map(a => this.patchAnnouncement(a));
            const deleteAnnouncements = this.form.announcements.delete.map(a => this.deleteAnnouncement(a, this.form.ids));
            const patchStreams = this.form.ids.map(id => this.patchStream(id));
      
            Promise.all([
              ...patchAnnouncements,
              ...postAnnouncements,
              ...deleteAnnouncements,
              ...patchStreams
            ]).then(() => {
              EventBus.$emit('refresh');
            }).catch((err) => {
              console.log({err});
              this.saveButtonDisabled = false;
              this.saveButtonLoading = false;
              this.showError();
            })

          })
          .catch((err) => {
            this.saveButtonDisabled = false;
            this.saveButtonLoading = false;
            console.error({err});
          })

        }
      } else {
        this.saveButtonDisabled = false;
        this.saveButtonLoading = false;
        this.showError(this.form.programValidation.message)
      }
      
    },

    momentsValid(){
      
      const result = this.form.moments.filter(m => m.enabled).map((moment) => {
        let message = "", valid = true;

        const startTime = this.timeInteger(moment.start_time);
        const endTime = this.timeInteger(moment.end_time);

        // end before start
        if (startTime >= endTime){
            message = `${moment.text}: minimale duur is 1 minuut`;
            valid = false;
        }
  
        // time overlap
        this.form.moments.filter(e => e.enabled && e.id != moment.id).forEach((e) => {
          const itemStart = this.timeInteger(e.start_time);
          const itemEnd = this.timeInteger(e.end_time);
          if (startTime <= itemEnd && endTime >= itemStart) {
            message = `${moment.text} (${moment.start_time} - ${moment.end_time}) overlapt met ${e.text} (${e.start_time} - ${e.end_time})`
            valid = false;
          }
        });

        return {message, valid}
      });

      const errors = result.filter(r => !r.valid);
      if (errors.length) this.showError(errors[0].message);
      return (errors.length == 0);
    },

    timeInteger(time){
      return parseInt(time.replace(':', ''));
    },

    selectYear(){
      this.changeYear(this.selectedYear);
    },

    showError(message = null, timeout = 3000){
      this.form.error.display = true;
      this.form.error.message = (message) ? message : this.$t("linkDevice.error");
      this.form.error.timeout = timeout;
    },

    toggleAllAccounts(){
      this.modal.tagRequest.form.accounts = this.allAccountsSelected
        ? []
        : this.accounts;
    },

    openRequestTag(){
      this.loadAccounts()
        .then(() => {
          this.modal.tagRequest.display = true;
        })

      this.modal.tagRequest.form.tag = this.searchInput;
      this.modal.tagRequest.form.name = this.user.name;
      this.modal.tagRequest.form.email = this.user.email;
      this.modal.tagRequest.form.accounts = this.form.ids.map(id => parseInt(id));
    },
    
    requestTag() {
      const data = {
        tag: this.modal.tagRequest.form.tag,
        remark: this.modal.tagRequest.form.remark,
        name: this.modal.tagRequest.form.name,
        email: this.modal.tagRequest.form.email,
        streams: this.modal.tagRequest.form.accounts,
      }

      console.warn({data});

      axios.post(`${ILLI_API_SERVER_URL}/tagRequest`, data).then((res) => {
        console.log({res});
        this.requestTagConfirmation = true;
        this.modal.tagRequest.display = false;
      }).catch((err) => {
        console.error({err});
        this.requestTagError = true;
      });

    },

    arrOfObjSame(arr1, arr2) {
      if (arr1.length !== arr2.length) {
        return false;
      }

      for (let i = 0; i < arr1.length; i++) {
        const obj1 = arr1[i];
        const obj2 = arr2[i];

        const keys1 = Object.keys(obj1);
        const keys2 = Object.keys(obj2);

        if (keys1.length !== keys2.length) {
          return false;
        }

        for (let key of keys1) {
          if (obj1[key] !== obj2[key]) {
            return false;
          }
        }
      }

      return true;
    },
  },
});
</script>

<style>
  .momentTable td:last-child .v-input__slot{
    justify-content: right;
  }
</style>

<style lang="scss" scoped>
  .hidden{
    display: none;
  }
  .manageLinkedDeviceContainer{
    margin-bottom: 56px;
  }
  .buttonContainer{
    position: fixed;
    bottom: 0;
    right: 0;
    width: 100%;
    background: #fff;
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    padding: 10px;
    z-index: 5;
  }
  .momentTable{
    width: 100%;
  }

  /* switch */
  .v-input--switch{
    margin: 0!important;
  }
  .v-application--is-ltr .v-messages{
    display: none;
  }

  /* clock picker */
  .clock-picker__input-container{
    position: relative;
    max-width: 300px;
  }
  .clock-picker__input-container:before {
    border-color: rgba(0,0,0,.42);
    border-style: solid;
    border-width: thin 0 0;
    bottom: -1px;
    content: "";
    left: 0;
    position: absolute;
    transition: .3s cubic-bezier(.25,.8,.5,1);
    width: 100%;
  }

  .clock-picker__input-container:after{
    background-color: blue;
    border-color: blue;
    border-style: solid;
    border-width: thin 0;
    transform: scaleX(0);
    bottom: -1px;
    content: "";
    left: 0;
    position: absolute;
    transition: .3s cubic-bezier(.25,.8,.5,1);
    width: 100%;
  }

  /* werkt niet... */
  .clock-picker__input--focused:after{ 
    transform: scaleX(1);
  }

  .clock-picker__input{
    border: 0;
    outline: none;
    width: 100%;
  }

  .v-expansion-panel-header{
    font-size: 16px!important;
  }
  .v-expansion-panel-header--active {
    font-weight: bold;
  }
  .v-expansion-panel-content {
    font-size: 15px;
  }

  .announcementForm{
    position: relative;
  }

  .announcementForm.disabled:after{
    content: '';
    height: 100%;
    width: 100%;
    position: absolute;
    background: #ffffff91;
    left: 0;
    top: 0;
    cursor: not-allowed;
  }

  .announcementForm .clock-picker{
    margin-top: 4px;
    margin-bottom: 22px;
  }

  .announcementForm .clock-picker__input-container{
    max-width: 100%;
  }

  .announcementForm .clock-picker label{
    position: relative;
    bottom: -17px;
    font-size: 16px;
    color: black;
    transform-origin: 0px 0px;
    transition-delay: 0s;
    transition-duration: 0.3s;
    transition-property: all;
    transition-timing-function: cubic-bezier(0.25, 0.8, 0.5, 1);
  }

  .announcementForm .clock-picker__input--has-value label{
    transform: translateY(-18px) scale(.75);
  }

  /* ------------------- */

  .announcementForm .inputBlock{
    background: #f1f1f1;
    padding: 10px;
    margin-bottom: 10px;
  }

  .announcementForm .inputBlock label{
    margin-bottom: 4px;
    width: 100%;
  }

  .announcementForm .inputBlock .mx-datepicker{
    margin-bottom: 5px;
  }

  .announcementsMenu{
    list-style: none;
    margin: 0!important;
    padding: 0!important;
  }
  
  .announcementsMenu li{
    margin-bottom: 5px;
  }

  .announcementsMenu li .v-btn.selected {
    background-color: #dbdbdb;
  }

  h4 {
    margin: 0 0 15px;
    font-size: 16px;
  }

  .inputRow{
    gap: 10px;
    margin-bottom: 14px;
    font-size: 12px;
  }

  .inputBlock button.v-btn.subtleButton{
    margin-top: -4px;
    height: unset!important;
  }

  button.v-btn.subtleButton{
    background: none!important;
    border: none;
    outline: none;
    box-shadow: none;
    margin-top: 20px;
    color: grey;
    padding: 0 0 0 5px!important;
    min-width: unset!important;
  }

  button.v-btn.subtleButton .v-icon{
    font-size: 20px;
  }

  .dayprogramContainer{
      height: calc(100vh - 24px - 100px); /* fallback */
      height: calc(100dvh - 24px - 100px);
      border: 1px solid grey;
      padding: 20px;
  }

  .photoAlbumContainer{
      border: 1px solid grey;
      padding: 20px;
  }



  @media screen and (max-width: 600px){
    .tagCategory:not(:last-child){
      border-bottom: 1px solid #f5f5f5;
    }
    /* .tagCategory:not(:last-child){
      border-right: 1px solid #f5f5f5;
    }   */
  }

  @media screen and (min-width: 601px) and (max-width: 960px){
    .tagCategory{
      border-bottom: none;

      &:nth-child(-n+3) {
        border-bottom: 1px solid #f5f5f5;
      }
      
      &:nth-child(1),
      &:nth-child(2),
      &:nth-child(4),
      &:nth-child(5) {
        border-right: 1px solid #f5f5f5;
      }
    }
  }

  @media screen and (min-width: 961px){
    .tagCategory{
      border-bottom: none;

      &:not(:last-child){
        border-right: 1px solid #f5f5f5;
      }
    }
  }

  @media screen and (max-width: 1263px){
      
      .dayprogramContainer{
          height: calc(100vh - 24px - 60px - 100px); /* fallback */
          height: calc(100dvh - 24px - 60px - 100px);
      }

  }

</style>